<template>
  <div class="Agreement">
    <div class="top-title">
      <div class="top-text">角色列表</div>
      
    </div>

      <div class="agreement-ul">
       
          <ul>
             <li><p>账号</p><p>密码</p><p>角色</p><p>操作</p></li>
            <li v-for="(e,i) in list" :key="i">
              <p>{{e.login_name}}</p>
              <p>**********</p>
              <p>{{e.role_name}}</p>
              <p><el-button type="info" @click="bianji(e)" >编辑</el-button></p>
            </li>
          </ul>
      </div>

  </div>
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
       list:[],
    };
  },
  created() {
      this.getSong();
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'AAAEntity',
        handleMode:'fetchPlatUserList',
         })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result
      },

// 跳转
    bianji(e){
        this.$router.push({ path: `/home/rolelistpar/${e.id}` });
    },



  },
  computed: {},
};
</script>


<style  scoped>

.agreement-ul{
  padding-top: 100px;
  width: 80%;
  margin: 0 auto;
}
.agreement-ul ul{
  width: 100%;
}
.agreement-ul ul li:nth-of-type(1) p{
  font-size: 18px;
  color: #000;
}
.agreement-ul ul li:nth-of-type(1){
  padding-bottom: 40px;
}
.agreement-ul ul li{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(153, 153, 153, 0.466);
  padding: 20px 0 10px ;
}
.agreement-ul ul li p{
width: 30%;
text-align: center
}
@import "../../assets/css/liststyle.css";
</style>